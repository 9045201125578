<template>
  <div class="h-auto self-start flex flex-col md:pt-[14px]">
    <div class="loading-effect title w-1/2 h-[30px]"></div>
    <div class="loading-effect description w-[70%] h-[20px] mt-2"></div>
    <div class="loading-effect meta w-[40%] h-[20px] mt-3"></div>
    <div class="flex mt-4">
      <div class="loading-effect w-[65%] gallery h-[200px] md:h-[420px]"></div>
      <div
        class="gallery-side w-[35%] pl-4 gap-4 flex flex-col md:flex-row md:flex-wrap"
      >
        <div class="loading-effect gallery-small"></div>
        <div class="loading-effect gallery-small"></div>
        <div class="loading-effect gallery-small"></div>
        <div class="loading-effect gallery-small"></div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '~/assets/css/loading.css';

.gallery-small {
  width: 100%;
  height: 92px;

  &:nth-child(3),
  &:nth-child(4) {
    display: none;
  }

  @media (min-width: 768px) {
    width: calc(50% - 8px);
    height: 200px;

    &:nth-child(3),
    &:nth-child(4) {
      display: inline-flex;
    }
  }
}
</style>
