<template>
  <div class="h-auto self-start flex flex-col">
    <div class="loading-effect breadcrumbs w-1/2 md:w-2/12 h-[20px]"></div>
    <div class="flex flex-col md:flex-row gap-8 mt-4 w-full">
      <div class="h-[260px] md:h-[480px] w-full loading-effect"></div>
      <div
        class="loading-effect sidebar w-full md:w-[43%] hidden lg:block h-[320px] md:h-[620px]"
      ></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '~/assets/css/loading.css';
</style>
