<script setup lang="ts">
import PageLoaderEventList from '~/components/loader/PageLoaderEventList.vue'
import PageLoaderStrapi from '~/components/loader/PageLoaderStrapi.vue'
import PageLoaderEventDetail from '~/components/loader/PageLoaderEventDetail.vue'
import PageLoaderMagazine from '~/components/loader/PageLoaderMagazine.vue'
import PageLoaderHome from '~/components/loader/PageLoaderHome.vue'

const nuxtApp = useNuxtApp()
const router = useRouter()
const loading = ref<boolean>(false)

/*
  Preloader components mapper,
  you can provide your own key using definePageMeta on any page
  
  definePageMeta({
    preloaderType: 'your-key',
  })
*/
const loadersTypeMapper: Component = {
  // default and fallback
  default: PageLoaderEventList,
  // for strapi static pages
  strapi: PageLoaderStrapi,
  // for event details
  eventDetail: PageLoaderEventDetail,
  // for blog
  magazine: PageLoaderMagazine,
  // homepage
  home: PageLoaderHome,
}
const loadingType = ref<string>('default')

nuxtApp.hook('page:start', (): void => {
  loading.value = true
  loadingType.value = router?.currentRoute?.value?.meta?.preloaderType
    ? router.currentRoute.value.meta.preloaderType
    : 'default'
})

nuxtApp.hook('page:finish', (): void => {
  loading.value = false
})
</script>

<template>
  <Transition name="fade">
    <div
      v-if="loading"
      class="preloader-wrapper fixed left-0 top-0 h-full flex justify-center w-full bg-white overflow-hidden"
    >
      <div class="pt-[138px] max-w-screen-xl mx-auto px-4 w-full">
        <component
          :is="loadersTypeMapper[loadingType] || loadersTypeMapper.default"
        />
      </div>
    </div>
  </Transition>
</template>

<style lang="postcss" scoped>
.preloader-wrapper {
  z-index: 19;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
  will-change: opacity;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
