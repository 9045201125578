<script setup lang="ts">
import type { INavLinkItem } from './NavigationLinks.vue'

const props = withDefaults(
  defineProps<{
    logoImg?: string
    extraLinkItems?: INavLinkItem[]
  }>(),
  { logoImg: '/_nuxt3-static/logos/logo.svg', extraLinkItems: () => [] }
)

const localePath = useLocalePath()
const subdom = useSubdomain()
const route = useRoute()
const { isMobile } = useDevice()
const isNavSidebarOpen = ref(false)

const toggleNavSidebar = () =>
  (isNavSidebarOpen.value = !isNavSidebarOpen.value)

const isMounted = ref(false)
onMounted(() => {
  isMounted.value = true
})
const defaultLinks = [
  { path: '/search', text: 'general.allEvents' },
  { path: '/giftcard', text: 'general.giftCards', showOnSub: true },
  { path: '/teamevent', text: 'general.teamEvents' },
  { path: '/partner', text: 'general.beAPartner' },
  { path: '/magazine', text: 'general.magazine' },
] as INavLinkItem[]

if (subdom.value) {
  defaultLinks.unshift({
    path: '/',
    text: 'general.allEvents',
    showOnSub: true,
  })
}
if (props.extraLinkItems && Array.isArray(props.extraLinkItems)) {
  defaultLinks.push(...props.extraLinkItems)
}

const navLinks = computed(() => {
  return defaultLinks.filter((l) => !subdom.value || l.showOnSub)
})

const routesWhereSearchInNavbarIsHidden = ['index___de-de', 'index___en-us']
const shouldShowFederatedSearchInCurrentRoute = computed(
  () => !routesWhereSearchInNavbarIsHidden.includes(route.name) && isMounted
)
</script>

<template>
  <div class="sticky z-20 top-0 start-0">
    <NuxtLazyHydrate when-idle>
      <CartSidebar />
    </NuxtLazyHydrate>

    <nav class="bg-white border-b sm:border-b-0 border-gray-200">
      <div
        class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 h-[74px]"
      >
        <HamburgerButton @navbar-open="toggleNavSidebar" />
        <NuxtLink
          :to="localePath('/')"
          class="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img :src="logoImg" class="h-6" alt="konfetti logo" />
        </NuxtLink>
        <div class="hidden sm:block grow">
          <FederatedSearchLoadingPlaceholder
            v-show="shouldShowFederatedSearchInCurrentRoute && !isMounted"
            class="fullwidth hidden sm:flex"
          />

          <LazyFederatedSearch
            v-if="
              shouldShowFederatedSearchInCurrentRoute && isMounted && !isMobile
            "
            element-id="navbar-autocomplete"
            :full-width="true"
            class="fullwidth hidden sm:block"
          />
        </div>
        <NuxtLazyHydrate when-idle>
          <NavbarUserMenu />
        </NuxtLazyHydrate>
      </div>
      <div class="px-1 pb-2 block sm:hidden">
        <NuxtLazyHydrate when-idle>
          <FederatedSearchLoadingPlaceholder
            v-if="shouldShowFederatedSearchInCurrentRoute && !isMounted"
            class="fullwidth flex sm:hidden"
          />
        </NuxtLazyHydrate>
        <LazyFederatedSearch
          v-if="
            shouldShowFederatedSearchInCurrentRoute && isMounted && isMobile
          "
          element-id="navbar-autocomplete"
          :full-width="true"
          class="fullwidth block sm:hidden"
        />
      </div>
    </nav>
    <NuxtLazyHydrate when-idle>
      <NavigationLinks
        :is-open="isNavSidebarOpen"
        :nav-links="navLinks"
        @navbar-close="toggleNavSidebar"
      />
    </NuxtLazyHydrate>
  </div>
</template>
<style scoped lang="postcss">
#navbar-autocomplete {
  @apply grow;
  @apply border border-gray-100 rounded-full;
  @apply mx-4 sm:mx-8;
}
</style>
