<template>
  <div class="h-auto self-start flex flex-col md:flex-row md:items-center">
    <div
      class="md:order-2 h-[210px] md:h-[450px] md:max-w-[60%] w-full loading-effect image ml-auto"
    ></div>
    <div
      class="md:order-1 flex w-full max-w-[450px] md:p-0 py-4 md:py-7 flex-col justify-center"
    >
      <div class="loading-effect title"></div>
      <div class="loading-effect text"></div>
      <div class="loading-effect button"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '~/assets/css/loading.css';
</style>
