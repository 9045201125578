<template>
  <div class="h-auto self-start flex flex-col mt-[-42px] md:mt-[-14px]">
    <div class="flex mt-4 mb-16">
      <div
        class="loading-effect w-full h-[400px] md:h-[420px] md:w-[65%] gallery"
      ></div>
      <div class="gallery-side flex-col hidden md:flex">
        <div class="loading-effect gallery-small"></div>
        <div class="loading-effect gallery-small"></div>
      </div>
      <div class="gallery-side hidden md:flex">
        <div class="loading-effect gallery"></div>
      </div>
    </div>
    <PageLoaderEventList is-horizontal-list />
  </div>
</template>

<style lang="postcss" scoped>
@import '~/assets/css/loading.css';

.title {
  width: 50%;
  height: 30px;
}

.description {
  width: 70%;
  height: 20px;
  margin-top: 8px;
}

.meta {
  width: 40%;
  height: 20px;
  margin-top: 12px;
}

.gallery-side {
  width: calc(35% / 2);
  padding-left: 16px;
  gap: 16px;

  .gallery {
    width: 100%;
    height: 100%;
  }
}

.gallery-small {
  width: 100%;
  height: 202px;
}
</style>
