<script setup lang="ts">
import Link from '~/components/common/Link.vue'
const { locale } = useI18n()
const currentLinksTab = ref(null)
const { data: eventLinksData } = await useStrapiSingleType(
  `event-list-pages-links-footer?locale=${locale.value}&populate[footerBlock][populate]=*`
)
const footerBlock = computed(() => {
  if (eventLinksData?.value?.data?.attributes?.footerBlock?.length) {
    currentLinksTab.value =
      eventLinksData?.value?.data?.attributes?.footerBlock[0]?.id
    return eventLinksData?.value?.data?.attributes?.footerBlock
  }
})
</script>
<template>
  <div class="max-w-screen-xl mt-20 md:mt-24 mb-10 md:mb-12 mx-auto px-4">
    <Heading
      :h-class="'h3'"
      :level="2"
      :title="$t('footer.usefulLinks')"
      :use-h-tag="false"
      class="mb-2 mb-md-4 kft-product-showcase__heading kft-heading--left"
    />
    <div class="flex flex-wrap justify-start gap-x-4">
      <Chip
        v-for="tab in footerBlock"
        :key="`app-links-tab-chip-${tab.id}`"
        :label="tab.title"
        :selected="tab.id === currentLinksTab"
        class="relative border-gray-200 hover:border-teal-900 hover:text-teal-900 h-[34px] md:h-[32px] px-3 mt-4"
        @click="currentLinksTab = tab.id"
      >
        {{ tab.title }}
      </Chip>
    </div>
    <div
      v-for="(section, i) in footerBlock"
      v-show="section.id === currentLinksTab"
      :key="`${section.id}-tabs`"
      class="grid grid-cols-2 md:grid-cols-3 mt-8"
      :data-test-id="`useful-links-${i}`"
    >
      <div
        v-for="(item, index) in section.items"
        :key="`section-${section.id}-${item.label}-tab-links-${index}`"
        :class="{
          'col-4': $device.isDesktopOrTablet,
          'col-6': !$device.isDesktopOrTablet,
        }"
      >
        <Link
          :link="localePath(item.link)"
          class="text-decoration-underline mb-5"
          >{{ item.label }}
        </Link>
      </div>
    </div>
  </div>
</template>
